<template>
  <div style="height: 100%">
    <nesi-user-common :title="`人工核验`">
      <template #content>
        <div class="container">
          <div class="step-wrapper" finish-status="success">
            <el-steps :active="2" align-center>
              <el-step title="查询权属信息"></el-step>
              <el-step title="填写房屋信息"></el-step>
              <el-step title="提交审核"></el-step>
            </el-steps>
          </div>
          <div class="wrapper">
            <el-form
              ref="houseFormRef"
              :model="form"
              :label-position="`right`"
              :rules="houseFormRules"
              layout="inline"
            >
              <nesi-section :title="`房屋信息`">
                <el-form-item
                  label="所在城区："
                  prop="district"
                  style="width: 48%"
                >
                  <el-select
                    v-model="form.district"
                    placeholder="请选择城区"
                    style="width: 100%"
                  >
                    <el-option label="兴宁区" value="兴宁区"></el-option>
                    <el-option label="青秀区" value="青秀区"></el-option>
                    <el-option label="江南区" value="江南区"></el-option>
                    <el-option label="西乡塘区" value="西乡塘区"></el-option>
                    <el-option label="良庆区" value="良庆区"></el-option>
                    <el-option label="邕宁区" value="邕宁区"></el-option>
                    <el-option label="武鸣区" value="武鸣区"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="房屋用途："
                  prop="housingUse"
                  style="width: 48%"
                >
                  <el-select
                    v-model="form.housingUse"
                    placeholder="请选择用途"
                    style="width: 100%"
                  >
                    <el-option label="住宅" value="住宅"></el-option>
                    <el-option label="商业" value="商业"></el-option>
                    <el-option label="办公" value="办公"></el-option>
                    <el-option label="车位" value="车位"></el-option>
                    <el-option label="车库" value="车库"></el-option>
                    <el-option
                      label="住宅、商业"
                      value="住宅、商业"
                    ></el-option>
                    <el-option label="工业" value="工业"></el-option>
                    <el-option label="仓储" value="仓储"></el-option>
                    <el-option label="医疗卫生" value="医疗卫生"></el-option>
                    <el-option label="科研" value="科研"></el-option>
                    <el-option label="酒店" value="酒店"></el-option>
                    <el-option label="杂物房" value="杂物房"></el-option>
                    <el-option label="厂房" value="厂房"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="套内面积："
                  prop="insideArea"
                  style="width: 48%"
                >
                  <el-input
                    v-model.trim="form.insideArea"
                    placeholder="请输入套内面积"
                  >
                    <template #append>㎡</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="建筑面积："
                  prop="buildingArea"
                  style="width: 48%"
                >
                  <el-input
                    v-model.trim="form.buildingArea"
                    placeholder="请输入建筑面积"
                  >
                    <template #append>㎡</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="出租类型："
                  prop="rentType"
                  style="width: 48%"
                >
                  <el-select
                    v-model="form.rentType"
                    placeholder="请选择出租类型"
                    style="width: 100%"
                  >
                    <el-option key="1" label="整租" value="1" />
                    <el-option key="2" label="分租" value="2" />
                  </el-select>
                </el-form-item>
                <el-form-item
                  style="width: 100%"
                  label="房屋坐落："
                  prop="location"
                >
                  <el-input
                    v-model.trim="form.location"
                    placeholder="请输入房屋坐落"
                  />
                </el-form-item>
                <el-form-item
                  style="width: 99%; margin-left: 10px"
                  label="房源备注："
                >
                  <el-input
                    v-model.trim="form.remarks"
                    :rows="2"
                    type="textarea"
                    maxlength="200"
                    placeholder="请输入备注"
                  />
                </el-form-item>
              </nesi-section>
              <nesi-section :title="`权利人信息`">
                <nesi-card
                  style="min-height: 285px; position: relative"
                  :title="item.qlrxm"
                  :isShowIcon="true"
                  v-for="item in ownerList"
                  :key="item.id"
                  :isShow="item.isShow"
                >
                  <nesi-field-item
                    :item="field"
                    v-for="field in item.fields"
                    :key="field.id"
                  ></nesi-field-item>
                  <div
                    v-if="!isHandleEdit"
                    class="btn-qlr-wrapper"
                    style="width: 100%; display: flex; padding: 10px 0"
                  >
                    <el-button
                      type="danger"
                      @click="delQlr(item)"
                      v-if="item.isShow"
                      >删除</el-button
                    >
                    <el-button
                      type="primary"
                      @click="editQlr(item)"
                      v-if="item.isShow"
                      >编辑</el-button
                    >
                    <el-button
                      type="primary"
                      v-if="item.fields[5].value === '-'"
                      @click="addPhone(item)"
                      >新增手机号码</el-button
                    >
                    <el-button
                      type="primary"
                      @click="addCert(item)"
                      v-if="item.isShow"
                      >上传证件</el-button
                    >
                  </div>
                </nesi-card>
                <nesi-card
                  style="min-height: 285px"
                  :title="`新增权利人`"
                  :isShowIcon="true"
                  v-if="!isHandleEdit"
                >
                  <div
                    @click="addOwner"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 210px;
                      width: 420px;
                    "
                  >
                    <i
                      class="el-icon-plus"
                      style="color: #999999; font-size: 30px"
                    ></i>
                  </div>
                </nesi-card>
              </nesi-section>
              <nesi-section
                :title="`需上传房屋所有权证（即主信息页、证号页、附记页、图纸页等）`"
              >
                <nesi-upload
                  @handleFile="handleFile"
                  @deleteFile="deleteFile"
                  :list="form.fileList"
                ></nesi-upload>
              </nesi-section>
              <el-form-item style="margin-top: 30px">
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <!-- <el-button>重置</el-button> -->
              </el-form-item>
            </el-form>
            <div class="tip-wrapper">
              <div class="title">
                <i class="el-icon-warning-outline mr-2"></i
                >房屋权属证明上传说明指导指南：
              </div>
              <div class="content">
                <span>
                  1.
                  房屋所有权证号：需上传房屋所有权证（即主信息页、证号页、附记页、图纸页等
                </span>
                <span>
                  2.
                  不动产权证号：需上传不动产权证（即主信息页、证号页、附记页、图纸页等）
                </span>
                <span>
                  3.
                  预售合同备案编号：需上传商品房买卖合同登记备案证明、竣工验收材料（或小区物业交付使用入住证明）
                </span>
                <span>
                  4.
                  商品房网签合同编号：需上传商品房预售合同登记备案表、竣工验收材料（或小区物业交付使用入住证明）
                </span>
                <span>
                  5.
                  其他证明：需上传国有土地使用证、建设工程规划许可证、竣工验收备案证明等相关权属证明
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </nesi-user-common>

    <!-- 弹出层 -->
    <el-dialog
      v-model="dialogFormVisible"
      title="新增权利人"
      width="60%"
      :top="`10vh`"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div class="search">
        <el-form
          :ref="setSForm"
          :rules="staffFormRules"
          :model="staffForm"
          label-width="120px"
          :inline="true"
        >
          <el-form-item label="权利人姓名" prop="name" style="width: 45%">
            <el-input
              v-model="staffForm.name"
              placeholder="请输入输入权利人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="权利类型：" prop="orgType" style="width: 45%">
            <el-select
              v-model="staffForm.orgType"
              placeholder="请选择权利类型"
              style="width: 100%"
            >
              <el-option label="所有权人" value="所有权人"></el-option>
              <el-option label="使用权人" value="使用权人"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="权利人类型："
            prop="peopleType"
            style="width: 45%"
          >
            <el-select
              v-model="staffForm.peopleType"
              placeholder="请选择权利人类型"
              style="width: 100%"
            >
              <el-option label="个人" value="个人"></el-option>
              <el-option label="单位" value="单位"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权证号：" style="width: 45%" prop="certNo">
            <el-input
              v-model="staffForm.certNo"
              placeholder="请输入输入权证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件类型：" prop="cardType" style="width: 45%">
            <el-select
              v-model="staffForm.cardType"
              placeholder="请选择证件类型"
              style="width: 100%"
            >
              <el-option label="居民身份证" value="居民身份证"></el-option>
              <el-option
                label="事业单位法人证书"
                value="事业单位法人证书"
              ></el-option>
              <el-option label="港澳台通行证" value="港澳台通行证"></el-option>
              <el-option label="港澳台身份证" value="港澳台身份证"></el-option>
              <el-option
                label="往来大陆通行证"
                value="往来大陆通行证"
              ></el-option>
              <el-option label="护照" value="护照"></el-option>
              <el-option label="户口簿" value="户口簿"></el-option>
              <el-option
                label="军官证（士兵证）"
                value="军官证（士兵证）"
              ></el-option>
              <el-option
                label="组织机构代码证"
                value="组织机构代码证"
              ></el-option>
              <el-option label="营业执照" value="营业执照"></el-option>
              <el-option
                label="统一社会信用代码"
                value="统一社会信用代码"
              ></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="证件号码：" style="width: 45%" prop="cardNo">
            <el-input
              :disabled="isEdit"
              v-model="staffForm.cardNo"
              placeholder="请输入输入权利人证件号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" style="width: 45%" prop="phoneNo">
            <el-input
              v-model="staffForm.phoneNo"
              placeholder="请输入输入权利人联系电话"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="qlrSubmit">确认</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="身份证正反两面(先传正面，再传反面)"
      width="60%"
      :top="`10vh`"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      v-model="certDialogVisible"
    >
      <nesi-upload
        @handleFile="handleCertFile"
        @deleteFile="deleteCertFile"
        :list="tempCertList"
        :limit="2"
      ></nesi-upload>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="certDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitCert" :loading="loading"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import { houseCheck, checkCardImg, getHouseById } from '@/api/user-house'
import NesiSection from '@/components/NesiSection/index.vue'
import NesiFieldItem from '@/components/NesiFieldItem/index.vue'
import NesiCard from '@/components/NesiCard/index.vue'
import NesiUpload from '@/components/NesiUpload/index.vue'
import { handleFields } from '@/utils/util'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'

defineComponent({
  NesiSection,
  NesiFieldItem,
  NesiCard,
  NesiUpload,
  NesiUserCommon
})

const store = useStore()
const route = useRoute()
const router = useRouter()
const id = ref(route.query.id)
const warrantData = computed(() => {
  return store.getters.warrantData
})
const warrantNo = warrantData.value.warrantNo
const warrantType = warrantData.value.warrantType
const ownerList = ref([])
const form = reactive({
  insideArea: '',
  housingUse: '',
  houseNumber: '',
  buildingArea: '',
  rentType: '',
  location: '',
  remarks: '',
  district: '',
  fileList: [],
  qlrList: [],
  houseId: '',
  businessNo: ''
})
const staffForm = reactive({
  name: '', // 权利人姓名
  peopleType: '', // 权利人类型
  orgType: '', // 权利类型
  certNo: warrantNo, // 权证号
  contractNo: '', // 网签合同号
  cardType: '', // 权利人证件类型
  cardNo: '', // 权利人证件号码,
  phoneNo: '', // 权利人联系电话
  certNoType: '', // 权证号类型
  id: '' // id
})

const certList = ref([])
const tempCertList = ref([])
const certDialogVisible = ref(false)
const handleCertFile = (data) => {
  certList.value.push({
    id: currentOption.value.id,
    smjmc: data.name,
    smjsj: data.path,
    uid: data.uid,
    url: data.minio + data.path
  })
}
const addCert = (item) => {
  currentOption.value = item
  certDialogVisible.value = true
  tempCertList.value = certList.value.filter(
    (item) => item.id == currentOption.value.id
  )
}

const deleteCertFile = (data) => {
  certList.value = certList.value.filter((item) => item.uid !== data.uid)
}

const currentOption = ref(null)
const certMessageList = ref([])
const certMessage = ref('')
const submitCert = async () => {
  const templist = certList.value.filter(
    (item) => item.id === currentOption.value.id
  )
  if (!templist || (templist && templist.length !== 2)) {
    ElMessage.error('请上传身份证正反面')
    return
  }
  // 调用身份证校验接口
  const params = {}
  if (templist && templist.length) {
    params.front = templist[0].smjsj
    params.back = templist[1].smjsj
  }
  const resp = await checkCardImg(params)
  if (resp && resp.code === 200) {
    if (resp.result.OcrIdCard !== currentOption.value.cardNo) {
      certMessage.value =
        '该权利人身份证号码[' +
        currentOption.value.cardNo +
        ']与上传证件时自动识别的证件号码[' +
        resp.result.OcrIdCard +
        ']不匹配，请重新确认！'
      ElMessage.error(certMessage.value)
    } else if (resp.result.OcrName !== currentOption.value.qlrxm) {
      certMessage.value =
        '该权利人姓名[' +
        currentOption.value.qlrxm +
        ']与上传证件时自动识别的姓名[' +
        resp.result.OcrName +
        ']不匹配，请重新确认！'
      ElMessage.error(certMessage.value)
    } else {
      certMessage.value = ''
      certDialogVisible.value = false
    }
  } else {
    certMessage.value = resp.message
    ElMessage.error(certMessage.value)
  }
  const hasOption = certMessageList.value.find(
    (item) => item.id === templist[0].id
  )
  if (hasOption) {
    hasOption.message = certMessage.value
  } else {
    const option = { id: templist[0].id, message: certMessage.value }
    certMessageList.value.push(option)
  }
}

const handleFile = (data) => {
  form.fileList.push({
    smjmc: data.name,
    smjsj: data.path,
    uid: data.uid,
    url: data.minio + data.path
  })
}

const deleteFile = (data) => {
  form.fileList = form.fileList.filter((item) => item.uid !== data.uid)
}

const resetForm = () => {
  staffForm.name = ''
  staffForm.peopleType = ''
  staffForm.orgType = ''
  staffForm.certNo = warrantNo
  staffForm.contractNo = ''
  staffForm.cardType = ''
  staffForm.cardNo = ''
  staffForm.phoneNo = ''
  staffForm.certNoType = ''
  staffForm.id = ''
}

const staffFormRules = reactive({
  name: [{ required: true, message: '请输入权利人姓名', trigger: 'blur' }],
  orgType: [{ required: true, message: '请选择权利类型', trigger: 'blur' }],
  peopleType: [
    { required: true, message: '请选择权利人类型', trigger: 'blur' }
  ],
  certNo: [{ required: true, message: '请输入权证号', trigger: 'blur' }],
  cardType: [
    { required: true, message: '请选择权利人证件类型', trigger: 'blur' }
  ],
  cardNo: [
    { required: true, message: '请输入权利人证件号码', trigger: 'blur' }
  ],
  phoneNo: [
    { required: true, message: '请输入权利人手机号码', trigger: 'blur' }
  ]
})
const houseFormRules = reactive({
  district: [{ required: true, message: '请选择城区', trigger: 'blur' }],
  housingUse: [{ required: true, message: '请选择用途', trigger: 'blur' }],
  insideArea: [{ required: true, message: '请输入套内面积', trigger: 'blur' }],
  buildingArea: [
    { required: true, message: '请输入建筑面积', trigger: 'blur' }
  ],
  location: [{ required: true, message: '请输入坐落', trigger: 'blur' }],
  rentType: [{ required: true, message: '请请选择出租方式', trigger: 'blur' }]
})
const user = computed(() => {
  return store.getters.userInfo
})
const dialogFormVisible = ref(false)

const houseFormRef = ref(null)
let sForm
const setSForm = function (el) {
  sForm = el
}
const originOwners = ref([])
const defOwners = reactive([
  {
    label: '权利类型：',
    name: 'orgType',
    value: '',
    fix: '',
    translate: []
  },
  {
    label: '权利人类型：',
    name: 'peopleType',
    value: '',
    fix: '',
    translate: []
  },
  { label: '权证号：', name: 'certNo', value: '', fix: '', translate: [] },
  {
    label: '证件类型：',
    name: 'cardType',
    value: '',
    fix: '',
    translate: []
  },
  {
    label: '证件号码：',
    name: 'cardNo',
    value: '',
    fix: '',
    translate: []
  },
  {
    label: '联系电话：',
    name: 'phoneNo',
    value: '',
    fix: '',
    translate: []
  }
])

const delQlr = (qlr) => {
  ElMessageBox.confirm('确定要删除该记录，是否继续?', '温馨提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    ownerList.value = ownerList.value.filter((item) => {
      const tempItem = item.fields.find((field) => field.name === 'cardNo')
      const tempQlrItem = qlr.fields.find((field) => field.name === 'cardNo')
      return tempQlrItem.value !== tempItem.value
    })
    originOwners.value = originOwners.value.filter((item) => {
      const tempQlrItem = qlr.fields.find((field) => field.name === 'cardNo')
      return tempQlrItem.value !== item.cardNo
    })
  })
}
const isEdit = ref(false)
const editQlr = (qlr) => {
  const tempSaffForm = originOwners.value.find((item) => {
    const qlrItem = qlr.fields.find((field) => field.name === 'cardNo')
    return qlrItem.value === item.cardNo
  })
  staffForm.cardNo = tempSaffForm.cardNo
  staffForm.cardType = tempSaffForm.cardType
  staffForm.certNo = tempSaffForm.certNo
  staffForm.certNoType = tempSaffForm.certNoType
  staffForm.contractNo = tempSaffForm.contractNo
  staffForm.name = tempSaffForm.name
  staffForm.orgType = tempSaffForm.orgType
  staffForm.peopleType = tempSaffForm.peopleType
  staffForm.phoneNo = tempSaffForm.phoneNo
  staffForm.id = tempSaffForm.id
  dialogFormVisible.value = true
  isEdit.value = true
}

const isHandleEdit = ref(false)
onMounted(async () => {
  if (id.value) {
    isHandleEdit.value = true
    const resp = await getHouseById({ houseId: id.value })
    const minioUrl = resp.minioUrl
    form.districtName = resp.houseIndfo.districtName
    form.housingUse = resp.houseIndfo.housingUse
    form.insideArea = resp.houseIndfo.insideArea
    form.buildingArea = resp.houseIndfo.buildingArea
    form.location = resp.houseIndfo.location
    form.remarks = resp.houseIndfo.remarks
    form.rentType = resp.houseIndfo.rentType.toString()
    form.district = resp.houseIndfo.districtName
    form.businessNo = resp.houseIndfo.businessNo
    form.houseId = resp.houseIndfo.houseId
    const qlrList = resp.houseIndfo.propertyOwnerInfo
      ? JSON.parse(resp.houseIndfo.propertyOwnerInfo)
      : []
    const images = resp.houseIndfo.propertyOwnerScan
      ? JSON.parse(resp.houseIndfo.propertyOwnerScan)
      : []

    form.fileList = images.map((item) => {
      return {
        ...item,
        url: minioUrl + item.smjsj
      }
    })
    const tempQlrList = qlrList.map((item) => {
      const option = {
        cardNo: item.qlrzjhm,
        cardType: item.qlrzjlx,
        certNo: item.qzh,
        certNoType: item.certNoType,
        name: item.qlrxm,
        orgType: item.qllx,
        peopleType: item.qlrlx,
        phoneNo: item.qlrlxdh
      }
      const fields1 = JSON.parse(
        JSON.stringify(handleFields(option, defOwners))
      )
      const isShow1 = option.cardNo !== user.value.cardNo
      ownerList.value.push({
        fields: fields1,
        qlrxm: option.name,
        cardNo: option.cardNo,
        isShow: isShow1,
        id: new Date().getTime()
      })
      return option
    })
    originOwners.value = tempQlrList
  } else {
    const defaultUser = {
      cardNo: user.value.cardNo,
      cardType: '居民身份证',
      certNo: warrantNo,
      certNoType: '1',
      contractNo: '',
      name: user.value.realname,
      orgType: '所有权人',
      peopleType: '个人',
      phoneNo: user.value.phone
    }
    const fields1 = JSON.parse(
      JSON.stringify(handleFields(defaultUser, defOwners))
    )
    const qlrxm1 = defaultUser.name
    const isShow1 = defaultUser.cardNo !== user.value.cardNo
    ownerList.value.push({
      fields: fields1,
      qlrxm: qlrxm1,
      cardNo: defaultUser.cardNo,
      isShow: isShow1,
      id: new Date().getTime()
    })
    originOwners.value.push(JSON.parse(JSON.stringify(defaultUser)))
  }
})

const addOwner = () => {
  dialogFormVisible.value = true
  isEdit.value = false
  resetForm()
}

const qlrSubmit = async () => {
  sForm.validate(async (valid) => {
    if (!valid) return
    const fields = JSON.parse(
      JSON.stringify(handleFields(staffForm, defOwners))
    )
    const qlrxm = staffForm.name
    const isShow = staffForm.cardNo !== user.value.cardNo
    if (!isEdit.value) {
      const isExist = originOwners.value.find((item) => {
        const result = item.cardNo === staffForm.cardNo
        return result
      })
      if (isExist) {
        ElMessage.error('权利人列表已存在该权利人信息')
        dialogFormVisible.value = false
        return
      }
      ownerList.value.push({
        fields: JSON.parse(JSON.stringify(fields)),
        cardNo: staffForm.cardNo,
        qlrxm,
        isShow,
        id: new Date().getTime()
      })
      staffForm.id = new Date().getTime()
      originOwners.value.push(JSON.parse(JSON.stringify(staffForm)))
      ElMessage.success('添加成功')
    } else {
      const index = ownerList.value.findIndex((item) => {
        return staffForm.cardNo === item.cardNo
      })
      ownerList.value = ownerList.value.filter((item) => {
        const fieldItem = item.fields.find((field) => field.name === 'cardNo')
        return staffForm.cardNo !== fieldItem.value
      })
      originOwners.value = originOwners.value.filter((item) => {
        return staffForm.cardNo !== item.cardNo
      })
      ownerList.value.splice(index, 0, {
        fields: JSON.parse(JSON.stringify(fields)),
        cardNo: staffForm.cardNo,
        qlrxm,
        isShow
      })
      originOwners.value.push(JSON.parse(JSON.stringify(staffForm)))
      ElMessage.success('编辑成功')
    }
    dialogFormVisible.value = false
  })
}

const loading = ref(false)
const onSubmit = async () => {
  houseFormRef.value.validate(async (valid) => {
    if (!valid) return
    if (certMessageList.value && certMessageList.value.length) {
      const option = certMessageList.value.find((item) => !!item.message)
      if (option) {
        ElMessage.error(option.message)
        return
      }
    }
    if (
      originOwners.value &&
      originOwners.value.length > 1 &&
      parseInt(certList.value.length / 2) !== originOwners.value.length - 1
    ) {
      ElMessage.error('请重新确认新增权利人是否已上传身份证正反面！')
      return
    }
    if (!form.fileList.length) {
      ElMessage.error('请上传房屋权属证明')
      return
    }
    const payload = (({
      remarks,
      district,
      location,
      insideArea,
      housingUse,
      houseNumber,
      buildingArea,
      rentType
    }) => ({
      remarks,
      district,
      location,
      insideArea,
      housingUse,
      houseNumber,
      buildingArea,
      rentType
    }))(form)
    payload.applyStatus = '0'
    payload.subleaseType = 0
    payload.businessNo = form.businessNo
    payload.houseId = form.houseId
    payload.applyID = new Date().getTime()
    payload.enterpriseId = ''
    payload.type = 1 // 访问者类型：1-房东 2-企业
    payload.warrantNo = warrantNo || originOwners.value[0].certNo
    payload.scans = form.fileList
    let certScan = []
    if (certList.value && certList.value.length) {
      certScan = certList.value.map((item) => {
        return {
          smjmc: item.smjmc,
          smjsj: item.smjsj
        }
      })
    }
    payload.scans = [...payload.scans, ...certScan]

    const rightHolderInfos = originOwners.value.map((qlr) => {
      return {
        qllx: qlr.orgType, // 权利类型
        qlrlx: qlr.peopleType, // 权利人类型
        qlrzjhm: qlr.cardNo, // 证件号码
        qlrzjlx: qlr.cardType, // 证件类型
        qzh: qlr.certNo, // 权证号
        wqhth: qlr.certNo,
        qlrlxdh: qlr.phoneNo, // 联系电话
        certNoType: warrantType || qlr.certNoType,
        qlrxm: qlr.name
      }
    })
    payload.rightHolderInfos = rightHolderInfos
    loading.value = true
    await houseCheck(payload)
    loading.value = false
    router.push('/user/house/result')
  })
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  // box-shadow: 0 0 50px 5px #e4e7ed;
  .step-wrapper {
    padding: 30px 0;
  }

  .wrapper {
    margin: 20px auto;
    border: solid 1px #e4e7ed;
    padding: 10px 10px;
    border-radius: 5px;
    transition: 0.2s;
    width: 95%;

    .form {
      width: 48%;
      height: 100%;
    }
  }
  .divider {
    width: 100%;
    border-top: 1px solid dotted;
    height: 1px;
  }
}

.tip-wrapper {
  margin-top: 50px;
  color: #ff9900;
  background: #fdf6ec;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-size: 14px;
  }
  .content {
    margin-top: 5px;
    font-size: 13px;

    p {
      height: 20px;
      line-height: 20px;
    }
  }
}
.mr-2 {
  margin-right: 2px;
}

.btn-wrapper {
  margin-top: 40px;
  padding: 0 10px;
}

.breadcrumb {
  padding: 0 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid #e4e7ed;
}

.tree-wrapper {
  height: 60vh;
  overflow-y: auto;
}

.search {
  display: flex;
  justify-content: center;
  // border-bottom: dotted 1px #e4e7ed;
}
</style>
